import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserBasicInfoDto } from '@ev-portals/cp/frontend/shared/api-client';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { map, Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class UserRegisterGuard {
  #authenticationService = inject(AuthenticationService);
  #navigationService = inject(NavigationService);

  readonly #pagesToAskForRegistration = ['/events', '/requests/send'];

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.#checkRegisterPopup(state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.#checkRegisterPopup(state);
  }

  #checkRegisterPopup(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url = state.url || '';

    return this.#authenticationService.user$.pipe(
      map(user => this.#handleRegisterPopup(user, url)),
    );
  }

  #handleRegisterPopup(user: UserBasicInfoDto | null, url: string): boolean | UrlTree {
    if (user) {
      return true;
    }

    if (localStorage.getItem('initial-register-popup-closed')) {
      if (this.#pagesToAskForRegistration.includes(url)) {
        this.#navigateToRegisterWithRedirect(url);
        return false;
      }
      return true;
    }

    this.#navigateToRegisterWithRedirect(url);
    return false;
  }

  #navigateToRegisterWithRedirect(url: string): void {
    this.#navigationService.storeOriginalDestination(this.#getRedirectUrl(url));
    this.#navigationService.navigateToRegister();
  }

  #getRedirectUrl(url: string): string {
    return this.#pagesToAskForRegistration.includes(url) ? '/products' : url;
  }
}
